@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  height: 150px;
  font-size: 12px;
  padding-bottom: 8px;
  margin-top: auto;
  z-index: 10;
  color: #FFF;
}
