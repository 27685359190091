@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.uploader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color_bg_shadow;
  z-index: 10;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;;
  height: max-content;
  padding:24px;
  border-radius: 5px;
  background-color: #FFF;

  @include on768 {
    width: 70%;
  }
  @include on1024 {
    width: 60%;
  }
}

.row {
  margin-bottom: 24px;

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: wrap;
    margin-bottom: 4px;
  }
}

.line {
  width: 100%;
  background-color: $color_grey_dark;
  height: 10px;
  border-radius: 5px;
  
  &__done {
    background-color: $color_green_dark;
    height: 100%;
    border-radius: 5px;
    transition: width .2s;
  }
}
