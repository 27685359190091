@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.processor {
	flex: 1;
	margin: 0 16px 24px;

	@include on768 {
		margin: 0 32px 24px;
	}
	@include on1024 {
		margin: 0 52px 24px;
	}
}

.row {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 24px;
	margin-bottom: 24px;
	width: 100%;
	max-width: 300px;
	
	@include on768 {
		flex-direction: row;
		align-items: end;
		max-width: none;
	}
	
	@include on1024 {
		margin: 0 auto 24px;
		max-width: 768px;
	}

	&__part {
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 12px;
		color: $color_red_dark;
		text-transform: uppercase;
	}
}

.text {
	display: block;
	width: 100%;
	margin: 0 auto 24px;
	color: $color_red_dark;
	
	@include on1024 {
		max-width: 768px;
	}
}

.input {
	width: 100%;
	padding: 10px 8px;
	border-radius: 4px;
	border: 1px solid $color_red_dark;
	outline: none;
	color: $color_red_dark;
	margin-bottom: 8px;

	@include on768 {
		max-width: 500px;
	}
}

.th {
	background-color: $color_red_dark;
	color: #fff;
	text-align: center;
}

.th,
.td {
	width: 14%;

	&:nth-child(2) {
		width: 20%;
	}

	&:nth-child(4) {
		text-align: center;
		width: 6%;
	}
	&:nth-child(5),
	&:nth-child(6) {
		text-align: center;
	}
}
