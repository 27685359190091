@import './variables';
@import './mixins';

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

html, input, select {
  font-size: 14px;
  font-family: Arial, sans-serif;

  @include on768 {
    font-size: 18px;
  }
  @include on1024 {
    font-size: 22px;
  }
}

input::placeholder {
  font-size: 12px;
  font-family: Arial, sans-serif;

  @include on768 {
    font-size: 16px;
  }
  @include on1024 {
    font-size: 20px;
  }
}

table {
	width: 100%;
	border-collapse: collapse;
}

th, td {
  border: 1px solid $color_red_light;
  overflow: hidden;
  word-break: break-word;
  font-size: 10px;
  padding: 3px;
  
  @include on768 {
    font-size: 14px;
    padding: 4px;
  }
  @include on1024 {
    font-size: 18px;
  }
}

textarea {
  resize: none;
  overflow: hidden; 
  height: 60px;
}