@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.area {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $color_bg_shadow;
	z-index: 10;
}

.modal {
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	padding: 24px;
	border-radius: 5px;
	background-color: #fff;
	gap: 24px;
	overflow: hidden;
}

.exit {
	display: flex;
	justify-content: end;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: max-content;
	margin: 0 auto 24px;

	@include on768 {
		gap: 24px;
	}
	@include on1024 {
    gap: 32px;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 12px;

		@include on768 {
			gap: 24px;
		}
		@include on1024 {
      gap: 32px;
		}
	}
}
