$color_green_dark: rgba(5, 130, 30, 0.8);
$color_green_light: rgba(0, 100, 70, 0.4);


$color_red_dark: rgba(90, 5, 55, 0.9);
$color_red_light: rgba(100, 15, 70, 0.7);


$color_up_down_load: rgb(40, 120, 255);
$color_yellow: rgb(240, 200, 15);
$color_error: rgba(230, 10, 140, 0.9);


$color_bg_shadow: rgba(0, 0, 0, 0.5);
$color_grey: rgba(245, 240, 244, 0.875);
$color_grey_dark: rgb(200, 200, 200);



