@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.header {
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
  margin: 12px 0;
  z-index: 10;
}
