@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color_bg_shadow;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.container {
  background: #FFF;
  padding: 40px;
  border-radius: 8px;  text-align: center;
  max-width: 300px;
}

.message {
  font-size: 16px;
  color: $color_error;
  text-transform: uppercase;
  margin-bottom: 22px;
}
