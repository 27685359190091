@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.btn {
	background-color: initial;
	border: none;
	outline: none;
}

.download {
	width: 12px;
	height: 12px;

	@include on768 {
		width: 16px;
		height: 16px;
	}

	&:hover {
		cursor: pointer;
		animation: btnAnimation 1s linear infinite;
	}

	&__head {
		width: 14px;
		height: 14px;

		@include on768 {
			width: 18px;
			height: 18px;
		}

		&:hover {
			cursor: initial;
			animation: none;
		}
	}

	&__notUploaded {
		&:hover {
			cursor: initial;
			animation: none;
		}
	}

	&__G {
		stroke: none;
		stroke-width: 0;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: none;
		fill-rule: nonzero;
		opacity: 1;
	}

	&__P {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		fill: $color_yellow;
		stroke-miterlimit: 10;
		fill-rule: nonzero;
		opacity: 1;

		&__head {
			fill: #fff;
		}
		&__downloaded {
			fill: $color_green_dark
		}
		&__notUploaded {
			fill: $color_error;
		}
	}
}

@keyframes btnAnimation {
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}
