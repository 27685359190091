@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.btn {
  display: flex;
  background-color: inherit;
  border: 1px solid $color_red_dark;
  border-radius: 4px;
  outline: none;
  padding: 4px 8px;
  color: $color_red_dark;
  text-transform: uppercase;
  transition: all .25s;
  
  &:hover {
    cursor: pointer;
    background-color: $color_red_dark;
    color: #FFF;
  }
}
