@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.check {
	display: flex;
	flex-direction: column;
}

.row {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 24px;
	width: 100%;
	max-width: 300px;

	&__part {
		display: flex;
		flex-direction: column;
	}
	
	&__text {
		color: $color_red_dark;
		text-transform: uppercase;
		margin-bottom: 8px;
	}

	&__refresh {
		margin: 0 auto 24px;
	}

	@include on768 {
		flex-direction: row;
		align-items: end;
		justify-content: space-between;
		margin-left: auto;
		margin-right: auto;
		max-width: none;
	}

	@include on1024 {
		max-width: 768px;
	}
}

.text {
	display: block;
	width: 100%;
	margin: 0 auto 24px;
	color: $color_red_dark;
	
	@include on1024 {
		max-width: 768px;
	}
}

.input {
	width: 100%;
	padding: 10px 8px;
	border-radius: 4px;
	border: 1px solid $color_red_dark;
	outline: none;
	color: $color_red_dark;
	margin-bottom: 8px;

	@include on768 {
		max-width: 500px;
	}
}

.options {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 32px;
}

// .radiolable {
// 	position: relative;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	color: $color_green_dark;

// 	&:hover {
// 		cursor: pointer;
// 	}

// 	&__isActive {
// 		&::after {
// 			content: '';
// 			position: absolute;
// 			bottom: -8px;
// 			left: 0;
// 			width: 100%;
// 			height: 2px;
// 			background-color: $color_red_dark;
// 			filter: blur(3px);
// 			pointer-events: none;
// 		}
// 	}
// }

// .hamster {
// 	height: 24px;
// }

.th {
	background-color: $color_red_dark;
	color: #fff;
	text-align: center;
}

.th,
.td {
	&:first-child {
		width: 15%;
	}
	&:nth-child(4) {
		text-align: center;
	}
	&:nth-child(5) {
		width: 7%;
		text-align: center;
	}
}

.errorText {
	display: block;
	text-align: center;
	color: $color_error;
	margin: 8px;
}
