@import './mixins';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  @include on768 {
    max-width: 860px;
  }

  @include on1024 {
    max-width: 1200px;
  }
}
