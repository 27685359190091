@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.add_del {
  & > table > tbody > tr > td:nth-child(2) {
    text-align: center;
  }
}

.th {
  width: 70%;
  background-color: $color_red_dark;
  color: #FFF;
  text-align: center;
  padding: 4px;
}

.td {
  padding: 4px;
}

.td__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 6px;
}