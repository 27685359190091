@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.btn {
	background-color: initial;
	border: none;
	outline: none;
}

.del {
	width: 8px;
	height: 8px;

  @include on768 {
    width: 12px;
	  height: 12px;
  }

	&__G {
		stroke: none;
		stroke-width: 0;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: none;
		fill-rule: nonzero;
		opacity: 1;
	}

	&__P1 {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: $color_error;
		fill-rule: nonzero;
		opacity: 1;
	}

	&__P2 {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: #FFF;
		fill-rule: nonzero;
		opacity: 1;
	}


	&:hover {
		cursor: pointer;
		animation: btnAnimation 1s linear infinite;
	}
}

@keyframes btnAnimation {
	0% {transform: scale(1)}
	25% {transform: rotate(15deg) scale(1.2)}
	50% {transform: scale(1.3)}
	74% {transform: rotate(-15deg) scale(1.2)}
	100% {transform: rotate(0) scale(1)}
}