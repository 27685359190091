@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.inspector {
  flex: 1;
  margin: 0 16px 24px;
  
  @include on768 {
    margin: 0 32px 24px;
  }
  @include on1024 {
    margin: 0 52px 24px;
  }
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  gap: 8px;
  margin-bottom: 24px;
}

.choice {
  text-align: center;
  color: $color_green_dark;
}

.th {
  background-color: $color_red_dark;
  color: #FFF;
  text-align: center;
  padding: 4px;
}

.td {
  padding: 4px;
}

.td__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 6px;
}
