@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.btn {
	position: fixed;
	bottom: 40px;
	right: 40px;
	background-color: initial;
	border: none;
	outline: none;
	z-index: 20;
}

.scroll {
	cursor: pointer;
	width: 22px;
	height: 22px;

	@include on768 {
		width: 28px;
		height: 28px;
	}
	@include on1024 {
		width: 44px;
		height: 44px;
	}

	&__G {
		stroke: none;
		stroke-width: 0;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: none;
		fill-rule: nonzero;
		opacity: .5;
		transition: all .3s ease-in-out;
	}

	&__P1 {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: $color_yellow;
		fill-rule: nonzero;
		opacity: 1;
	}
	&__P2 {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: #FFF;
		fill-rule: nonzero;
		opacity: 1;
	}

	&:hover {
		.scroll__G {
			opacity: 1;
		}
	}
		
		// 	animation: btnAnimationHover 1s linear infinite;
	// &__animation, &__animation:hover {
	// 	.refresh__P {
	// 		animation: btnAnimationColor 2s ease-out;
	// 	}

	// 	animation: btnAnimationRotate 2s ease-out;
	// }
}

// @keyframes btnAnimationHover {
// 	25% {transform: rotate(15deg)}
// 	74% {transform: rotate(-15deg)}
// 	100% {transform: rotate(0)}
// }

// @keyframes btnAnimationColor {
// 	0% {
// 		fill: $color_up_down_load;
// 	}
// 	40% {
// 		fill: $color_yellow; 
// 	}
// 	60% {
// 		fill: $color_yellow; 
// 	}
// 	100% {
// 		fill: $color_up_down_load;
// 	}
// }

// @keyframes btnAnimationRotate {
// 	0% {
// 		transform: rotate(0deg);
// 	}

// 	100% {
// 		transform: rotate(360deg);
// 	}
// }
