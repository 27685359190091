@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color_bg_shadow;
  z-index: 100;
}

.spiner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 5px dashed $color_grey;
  border-radius: 50%;
  animation: rotate 2s linear infinite;
  z-index: 101;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) rotate(180deg) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg) scale(1);
  }
}