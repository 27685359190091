@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.cart {
	width: 24px;
	height: 24px;

	&__G {
		stroke: none;
		stroke-width: 0;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: none;
		fill-rule: nonzero;
		opacity: 1;
	}

	&__pol {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: $color_red_dark;
		fill-rule: nonzero;
		opacity: 1;
	}

	&__path {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: $color_green_dark;
		fill-rule: nonzero;
		opacity: 1;
	}

	&__c {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: $color_red_dark;
		fill-rule: nonzero;
		opacity: 1;
	}
}
