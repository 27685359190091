@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.researcher {
  width: 14px;
  height: 14px;

  @include on768 {
    width: 20px;
    height: 20px;
  }
  @include on1024 {
    width: 32px;
    height: 32px;
  }

  &__G {
    fill: #FFF;
		stroke: none;
  }
}
