@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.done {
	width: 12px;
	height: 12px;

	@include on768 {
		width: 18px;
		height: 18px;
	}
	@include on1024 {
		width: 22px;
		height: 22px;
	}

	&__G {
		stroke: none;
		stroke-width: 0;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: none;
		fill-rule: nonzero;
		opacity: 1;
	}
	&__P {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: $color_green_dark;
		fill-rule: nonzero;
		opacity: 1;
	}
}
