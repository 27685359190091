@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.wellcome {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
  color: $color_red_dark;
}

.text {
  display: flex;
  gap: 4px;
  
  @include on768 {
    gap: 6px;
  }
  @include on1024 {
    gap: 8px;
  }
}

.name {
  font-weight: bold;
  cursor: pointer;
}